export const seo = {
  title: "Оферта на настройку и монтаж оборудования – EnergyTool",
  description:
    "Ознакомьтесь с офертой на на настройку и монтаж оборудования EnergyTool на нашем сайте.",
};

export const pageTitle = "Оферта на настройку и монтаж оборудования";
export const pageContent = [
  {
    title: "1. Термины и определения",
    text: [
      "<b>МТС</b> – Публичное акционерное общество «Мобильные ТелеСистемы» (место нахождения: Российская Федерация, 109147, г. Москва, ул. Марксистская, д. 4, ИНН/КПП 7740000076/770901001, ОГРН 1027700149124).",
      "<b>Работы</b> – Работы по монтажу и настройке оборудования, определенные в Заказе.",
      "<b>Клиент</b> – юридическое лицо (или индивидуальный предприниматель), принявший условия Договора.",
      "<b>Заказ</b> – документ, подписываемый между МТС и Клиентом, подтверждающий принятие условий Договора и определяющий перечень Работ, сроки их выполнения и цену Работ.",
      "<b>Представитель</b> – лицо, уполномоченное Клиентом на подписание Заказа, приемку Работ и другие необходимые действия и действующее от имени и в интересах Клиента.",
      "<b>Сайт</b> – страница в сети Интернет, размещенная по адресу: <a href='https://energytool.mts.ru/' target='_blank' rel='noreferrer'> https://energytool.mts.ru/</a>.",
      "<b>Стороны</b> – совместно МТС и Клиент.",
      "<b>Объект</b> – помещение, находящееся на законных основаниях в собственности или во владении и пользовании Клиента, на котором необходимо выполнить Работы.",
    ],
  },
  {
    title: "2. Предмет Договора",
    text: [
      "2.1. МТС обязуется выполнять по Заказу Работы на Объекте, а Клиент обязуется принять результаты Работ и оплатить их.",
    ],
  },
  {
    title: "3. Заключение Договора",
    text: [
      "3.1. Для присоединения к Договору Клиенту необходимо подписать с МТС Заказ.",
      "3.2. Договор считается заключенным с момента подписания Сторонами Заказа.",
    ],
  },
  {
    title: "4. Права и обязанности Сторон",
    text: [
      "4.1. Клиент обязуется:",
      "  (1) своевременно обеспечивать МТС всеми необходимыми для выполнения им своих обязательств документами и информацией;",
      "  (2) оказывать МТС необходимое содействие, оперативно решать все вопросы, которые могут возникнуть в ходе выполнения Работ;",
      "  (3) при обнаружении недостатков результатов Работ немедленно указывать на них МТС;",
      "  (4) передавать МТС Объект, территория которого пригодна для выполнения Работ. Особые требования к Объекту могут быть согласованы Сторонами в Заказе;",
      "  (5) назначить полномочного представителя для оперативного рассмотрения и решения технических и организационных вопросов, связанных с выполнением Работ;",
      "  (6) обеспечить допуск персонала МТС на Объект на весь период проведения Работ;",
      "  (7) обеспечивать МТС электроэнергией в объеме и мощностью, необходимыми для выполнения Работ.",
    ],
  },
  {
    title: "",
    text: [
      "4.2. МТС обязуется:",
      "(1) выполнять Работы качественно, в полном объёме;",
      "(2) устранять выявленные в ходе выполнения Работ и приемки результата Работ недостатки за свой счет и в срок, согласованный Сторонами;",
      "(3) немедленно информировать Клиента обо всех обстоятельствах, препятствующих исполнению условий Договора;",
      "(4) выполнять свои обязательства с учетом применимых нормативных документов (в том числе, но не ограничиваясь, соблюдать правила, установленные нормативными документами по охране труда, пожарной безопасности, требования промышленной безопасности при выполнении Работ);",
      "(5) проводить необходимые подготовительные мероприятия для начала выполнения Работ;",
      "(6) своевременно приобретать и доставлять на Объект необходимые материалы, оборудование, изделия, конструкции, приспособления, технику;",
      "(7) обеспечивать своими силами и за свой счет хранение инструмента, оборудования и оснастки на период проведения Работ;",
      "(8) доставлять на Объект приобретаемые за свой счет необходимые материалы и осуществлять их приемку, разгрузку, складирование и сохранность в период выполнения Работ;",
      "(9) не допускать использования в ходе исполнения обязательств материалы, оборудование, приспособления, которые могут привести к нарушению санитарных норм/правил, а также требований по безопасности работ и охране окружающей среды;",
      "(10) до момента приёмки Клиентом результата Работ вывозить принадлежащие МТС упаковочный материал, машины и оборудование, транспортные средства, инструменты, приборы, инвентарь, строительные материалы, изделия, конструкции и другое имущество, а также мусор.",
    ],
  },
  {
    title: "",
    text: [
      "4.3. Клиент имеет право осуществлять контроль за выполнением Работ, не вмешиваясь в оперативно-хозяйственную деятельность МТС.",
    ],
  },
  {
    title: "",
    text: [
      "4.4. МТС имеет право:",
      "  (1) привлекать для выполнения Работ третьих лиц (субподрядчиков), за деятельность которых МТС несет ответственность;",
      "  (2) не приступать к Работам или приостановить выполнение Работ при наличии препятствий к их выполнению, в том числе при недопуске на Объект, неготовности Объекта к проведению Работ. Если такие препятствия не будут устранены в течение 2 (двух) рабочих дней, МТС вправе в одностороннем порядке отказаться от Договора, потребовав от Клиента компенсации убытков;",
      "  (3) самостоятельно организовывать свою работу;",
      "  (4) отказаться в одностороннем порядке от Договора и потребовать компенсации убытков в случае просрочки исполнения обязательств Клиентом более чем на 10 (десять) дней.",
    ],
  },
  {
    title: "5. Цена и порядок расчетов",
    text: [
      "5.1. Цена Работ определяется в Заказе в зависимости от вида Работ и их количества.",
      "5.2. Расчеты производятся в рублях путем перечисления Клиентом безналичных денежных средств на расчетный счет МТС.",
      "5.3. Моментом исполнения денежного обязательства Клиента считается момент зачисления соответствующих денежных средств на расчётный счёт МТС.",
      "5.4. Клиент обязуется оплатить Работы в срок не позднее 15 (Пятнадцати) календарных дней с момента подписания Сторонами Акта сдачи-приёмки результата Работ на основании выставленного МТС счёта.",
      "5.5. Стороны согласовали, что в счете, выставляемом МТС Клиенту на оплату, Работы обозначаются как «EnergyTool. Настройка/Монтаж».",
      "5.6. В стоимость Работ включены монтажные работы по установке и настройке оборудования, включая расходные материалы.",
      "5.7. Оплата по настоящему Договору осуществляется исключительно с расчетного счета Клиента. В случае отсутствия расчетного счета у индивидуальных предпринимателей допускается оплата денежным переводом, при условии совпадения фамилии, имени, отчества, ИНН отправителя денежного перевода с фамилией именем отчеством, ИНН Клиента-индивидуального предпринимателя.",
      "5.8. Обязательство Клиента по оплате считается исполненным в момент зачисления денежных средств на корреспондентский счет банка МТС.",
      "8.2. Стороны признают юридическую и доказательную силу электронных сообщений, указанных в п. 8.1 Договора, в том числе в целях доказывания в арбитражном, гражданском, административном и уголовном процессах, в том числе для подтверждения соблюдения обязательного претензионного порядка разрешения споров.",
    ],
  },
  {
    title: "6. Условия приемки Работ",
    text: [
      "6.1. МТС после проведения Работ уведомляет об этом Клиента. Клиент обязан в день выполнения Работ на Объекте проверить объём и качество результата Работ, и при отсутствии недостатков принять результат Работ путем подписания Акта сдачи-приёмки результата Работ (далее – Акт). МТС обязуется подписать Акт после подписания Акта Клиентом.",
      "6.2. В случае допущения недостатков в результате проведённых Работ Клиент имеет право не подписывать Акт и обязан в течение 2 (двух) рабочих дней, считая с даты окончания срока проверки объёма и качества выполненных Работ, предоставить МТС письменный мотивированный отказ от подписания такого Акта.",
      "6.3. Результат работ считается принятым Клиентом с момента подписания им Акта. Если Клиент в срок не более 2 (двух) рабочих дней с момента окончания срока для проверки объёма и качества Работ не предоставил МТС ни подписанного им Акта, ни мотивированного отказа от подписания Акта, то Акт считается подписанным Клиентом.",
      "6.4. В случае выявления недостатков в результатах Работ, указанных Клиентом, МТС обязуется в разумный срок устранить такие недостатки и направить Клиенту новый Акт на подписание. Клиент обязуется подписать направленный Акт в течение 3 (трех) рабочих дней с даты направления Акта. В случае если Клиент не подписал указанный Акт в установленный срок, либо не представил очередной мотивированный отказ от подписания Акта, Акт считается подписанным Клиентом.",
    ],
  },
  {
    title: "7. Ответственность Сторон",
    text: [
      "7.1. В случае просрочки исполнения Клиентом денежных обязательств по оплате МТС вправе потребовать у Клиента уплаты неустоек (пеней). Пеня начисляется за каждый день просрочки исполнения обязательств по оплате, предусмотренных настоящим Дополнением, начиная со дня, следующего за последним днем срока исполнения соответствующего обязательства. Такая пеня устанавливается в размере 0,1% (одной десятой процента) от неуплаченной в срок суммы за каждый день просрочки по оплате.",
      "7.2. МТС в любом случае не будет нести ответственности в виде упущенной выгоды.",
      "7.3. Неустойки, предусмотренные в Дополнении, являются штрафными. При неисполнении или ненадлежащем исполнении обязательства нанесенные убытки подлежат возмещению в полной сумме сверх суммы неустойки.",
      "7.4. Стороны пришли к соглашению о том, что в порядке п. 1 ст. 15 Гражданского кодекса РФ ответственность МТС в части компенсации убытков Клиента по настоящему Соглашению в любом случае ограничена суммой всех выплат, полученных от Клиента в ходе его исполнения. Ответственность может быть возложена на МТС только при наличии в его действиях вины.",
    ],
  },
  {
    title: "8. Обмен электронными сообщениями",
    text: [
      "8.1. Электронные сообщения, отправленные посредством электронной почты, являются электронными документами, равнозначными документам на бумажном носителе, подписанным собственноручной подписью Клиента (при отправке электронного сообщения Клиентом/Представителем и/или электронного письма с адреса электронной почты, указанного в Заказе) или уполномоченного лица МТС (при отправке личного сообщения от имени МТС и/или электронного письма с корпоративного адреса электронной почты МТС).",
      "8.2. Стороны признают юридическую и доказательную силу электронных сообщений, указанных в п. 9.1 Договора, в том числе в целях доказывания в арбитражном, гражданском, административном и уголовном процессах, в том числе для подтверждения соблюдения обязательного претензионного порядка разрешения споров.",
      "8.3. В случае изменения наименования Сторон, их юридического статуса, адресов и/или расчётных реквизитов Сторон, а также иных изменений, способных повлиять на ход и результаты исполнения Договора, Сторона, у которой произошли указанные изменения, обязана уведомить об этом другую Сторону не позднее 3 (трех) рабочих дней с момента вступления в силу таких изменений. При этом заключения между Сторонами какого-либо дополнительного соглашения не требуется. Сторона несет ответственность за любые негативные последствия, связанные с неисполнением ею обязательств, установленных настоящим пунктом.",
    ],
  },
  {
    title: "9. Конфиденциальность.",
    text: [
      "В течение срока действия Договора, а также по истечении срока его действия Стороны обязуются не разглашать и не использовать в своих интересах, равно как и в интересах любых третьих лиц, любую конфиденциальную информацию, в том числе деловую, коммерческую, техническую и иную информацию, которая не может быть известна Сторонам из общедоступных источников, переданную одной Стороной другой Стороне и которая стала известной Сторонам в связи с заключением и исполнением Договора.",
    ],
  },
  {
    title: "10. Подсудность",
    text: [
      "В соответствии со статьей 37 Арбитражного процессуального кодекса РФ Стороны пришли к соглашению об изменении подсудности, установленной статьями 35 и 36 Арбитражного процессуального кодекса РФ, следующим образом.",
      "Если иное не установлено абзацем 3 настоящего пункта, то иск (заявление), вытекающий из Договора, в том числе касающийся его исполнения, изменения, нарушения, прекращения, незаключенности или недействительности, предъявляется в арбитражный суд по месту нахождения истца или по адресу любого из филиалов истца. Выбор между арбитражными судами, которым согласно настоящему пункту подсудно дело, принадлежит истцу.",
      "Если цена иска, вытекающего из Договора, превышает 15 000 000 рублей, то такой иск предъявляется в Арбитражный суд города Москвы.",
    ],
  },
  {
    title: "11. Действие, изменение и прекращение Договора",
    text: [
      "11.1. Договор действует до полного исполнения обязательств Сторонами.",
      "11.2. МТС вправе в одностороннем порядке вносить изменения в настоящий Договор путем уведомления Клиента посредством размещения новых редакций Договора на Сайте не менее чем за 5 (пять) дней до даты внесения изменений.",
    ],
  },
];
