import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import { device } from "theme";

export const SSubItem = styled(NavLink)`
  padding: 10px 8px;
  text-decoration: none;
  display: block;
  position: relative;
  text-align: left;

  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    transition: 0.3s ease all;
  }

  &.active {
    & .MuiTypography-root {
      color: ${(props) => props.theme.mtsColor.red};
      transition: 0.3s ease all;
    }
  }
`;

export const SLink = styled(Link)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 0px;
`;

export const SHeadWrap = styled(NavLink)`
  display: flex;
  min-height: 0;
  padding: 20px 0 20px 20px;
  transition: 0.3s ease all;
  justify-content: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    transition: 0.3s ease all;
  }

  &.active {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    transition: 0.3s ease all;

    & .MuiTypography-root {
      font-weight: 500;
    }
  }

  @media ${device.desktopPlus} {
    height: 56px;
  }
`;

export const SAccBody = styled.div`
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
  padding: 0 0 0 20px;
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    text-align: left;
  }
`;
